// src/components/AddressSelection.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getUserAddresses,
  createAddress,
  updateAddress,
  removeAddress,
} from "../actions/addressActions";
import { CiSquareRemove } from "react-icons/ci";
import { setShippingAddress } from "../actions/cartActions";
import axios from "axios";
import { scrollToTop } from "./ScrollToTop";

const AddressSelection = ({ setSelectedAddressId, setIsAdressSelected }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [newAddress, setNewAddress] = useState({}); // State for new address form
  const [selectedLabel, setSelectedLabel] = useState("Home");
  const [customLabel, setCustomLabel] = useState("");
  const [editingAddressId, setEditingAddressId] = useState(null); // Track editing state
  const [showForm, setShowForm] = useState(false);
  const [shippingAddId, setShippingAddId] = useState(null);

  const addressList = useSelector((state) => state.addressList);
  const { addresses } = addressList;

  const addressCreate = useSelector((state) => state.addressCreate);
  const { success: successCreate } = addressCreate;

  const addressDelete = useSelector((state) => state.addressDelete);
  const { success: successDelete } = addressDelete;

  useEffect(() => {
    scrollToTop();
    dispatch(getUserAddresses());
    if (successDelete) {
      dispatch(setShippingAddress(null));
      setShippingAddId(null);
      setSelectedAddressId(null);
      setIsAdressSelected(false);
    }
  }, [dispatch, successCreate, successDelete]);

  useEffect(() => {
    if (addresses && addresses.length > 0) {
      dispatch(setShippingAddress(addresses[0]?._id));
      setShippingAddId(addresses[0]?._id);
      setSelectedAddressId(addresses[0]?._id);
      setIsAdressSelected(true);
    }
  }, [addresses, successCreate]);

  const [errors, setErrors] = useState({}); // State to track validation errors

  // Validation function
  const validateForm = () => {
    const newErrors = {};
    if (!newAddress.name || newAddress.name.trim() === "") {
      newErrors.name = "Full name is required";
    }
    if (!newAddress.number || !/^\d{10}$/.test(newAddress.number)) {
      newErrors.number = "Valid phone number is required";
    }
    if (!newAddress.address || newAddress.address.trim() === "") {
      newErrors.address = "Address is required";
    }
    if (
      !newAddress.postalCode ||
      newAddress.postalCode.toString().length !== 6
    ) {
      newErrors.postalCode = "Valid postal code is required";
    }
    if (!newAddress.city || newAddress.city.trim() === "") {
      newErrors.city = "City is required";
    }
    if (!newAddress.state || newAddress.state.trim() === "") {
      newErrors.state = "State is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  // Submit handler
  const handleAddAddress = () => {
    if (validateForm()) {
      dispatch(createAddress(newAddress));
      setNewAddress({}); // Clear form
      setShowForm(!showForm);
      setErrors({}); // Clear errors after successful submission
    }
  };

  // const handleAddAddress = () => {
  //   dispatch(createAddress(newAddress));
  //   setNewAddress({}); // Clear form
  //   setShowForm(!showForm);
  // };

  const handleEditAddress = (addressId) => {
    const addressToEdit = addresses.find((addr) => addr._id === addressId);
    setNewAddress(addressToEdit);
    setEditingAddressId(addressId);
    setShowForm(!showForm);
  };

  const handleUpdateAddress = () => {
    dispatch(updateAddress(editingAddressId, newAddress));
    setEditingAddressId(null);
    setNewAddress({});
  };

  const handleRemoveAddress = (addressId) => {
    dispatch(removeAddress(addressId));
    dispatch(setShippingAddress(null));
    setIsAdressSelected(false);
    setShippingAddId(null);
    setSelectedAddressId(null);
  };

  const handlePlaceOrder = (addressId) => {
    // Save the selected address in the cart state
    setShippingAddId(addressId);
    setSelectedAddressId(addressId);
    setIsAdressSelected(true);
    dispatch(setShippingAddress(addressId));

    // Navigate to the payment page
    // navigate("/payment");
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "radio") {
      setSelectedLabel(value);
      if (value !== "other") {
        setNewAddress((prevData) => ({
          ...prevData,
          label: value,
        }));
      }
    } else if (name === "customLabel") {
      setCustomLabel(value);
      setNewAddress((prevData) => ({
        ...prevData,
        label: value,
      }));
    } else {
      setNewAddress((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const fetchStateCity = async (e) => {
    const { name, value, type } = e.target;
    if (value?.length === 6) {
      const { data } = await axios.get(
        `https://api.postalpincode.in/pincode/${value}`
      );
      setNewAddress((prevData) => ({
        ...prevData,
        city: data[0]?.PostOffice[0].District,
        state: data[0]?.PostOffice[0].State,
      }));
    }
  };

  return (
    <div className="container mx-auto p-6 bg-white">
      <h2 className="text-2xl mb-4">Select Shipping Address</h2>
      {addresses?.length === 0 ? (
        <p>No addresses found</p>
      ) : (
        <div className="grid grid-cols-1 gap-10">
          <div>
            {addresses?.map((address) => (
              <div
                key={address._id}
                className={`flex relative items-center gap-6 my-4 p-4 border-2 ${
                  shippingAddId === address._id
                    ? "border-[#000080]"
                    : "border-gray-100"
                }`}
              >
                <div className="flex flex-col text-[12px] font-semibold gap-2">
                  <h3>{address.label}</h3>
                  <div>{address.name}</div>
                  <div>{address.number}</div>
                  <div>{address.address}</div>
                  <div>
                    {address.city}, {address.state}, {address.postalCode}
                  </div>
                  <div>{address.country}</div>
                  <div>{address.isDefault ? "Default Address" : ""}</div>
                  <div className="flex gap-2">
                    <button
                      onClick={() => {
                        handleEditAddress(address._id);
                        setShowForm(!showForm);
                      }}
                      className="px-2 py-1 border bg-black text-white"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handlePlaceOrder(address._id)}
                      className="px-2 py-1 border bg-[#000080] text-white"
                    >
                      Deliver Here
                    </button>
                  </div>
                </div>
                <button
                  onClick={() => handleRemoveAddress(address._id)}
                  className="absolute top-4 right-4 px-2 py-1"
                >
                  <CiSquareRemove className="w-6 h-6" />
                </button>
              </div>
            ))}
            <div className="flex w-full items-center justify-center">
              <button
                onClick={() => setShowForm(!showForm)}
                className="font-bold bg-[#000080] px-4 py-1 rounded-md text-white"
              >
                Add New Address
              </button>
            </div>
          </div>

          {showForm && (
            <div className="flex flex-col gap-6 mt-4">
              <form className="space-y-6 grid grid-cols-2 gap-x-6">
                {/* Address Label */}
                <div className="mb-4 col-span-2">
                  <label className="block text-gray-700 text-sm font-semibold mb-2">
                    Address Label
                  </label>
                  <div className="flex gap-4 items-center">
                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="label"
                        value="Home"
                        checked={selectedLabel === "Home"}
                        onChange={handleChange}
                        className="form-radio text-[#000080]"
                      />
                      Home
                    </label>

                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="label"
                        value="Office"
                        checked={selectedLabel === "Office"}
                        onChange={handleChange}
                        className="form-radio text-[#000080]"
                      />
                      Office
                    </label>

                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="label"
                        value="Other"
                        checked={selectedLabel === "Other"}
                        onChange={handleChange}
                        className="form-radio text-[#000080]"
                      />
                      Other
                    </label>

                    {selectedLabel === "Other" && (
                      <input
                        type="text"
                        name="customLabel"
                        value={customLabel}
                        onChange={handleChange}
                        placeholder="Enter custom label"
                        className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                      />
                    )}
                  </div>
                </div>

                {/* Address Input */}
                <div className="mb-4 col-span-2">
                  <label className="block text-gray-700 text-sm font-semibold mb-2">
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    required
                    value={newAddress.name}
                    onChange={handleChange}
                    className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                    placeholder="Enter your name"
                  />
                  {errors.name && (
                    <p className="text-red-500 text-xs">{errors.name}</p>
                  )}
                </div>

                {/* Address Input */}
                <div className="mb-4 col-span-2">
                  <label className="block text-gray-700 text-sm font-semibold mb-2">
                    Phone
                  </label>
                  <input
                    type="tel"
                    name="number"
                    required
                    maxLength={10}
                    value={newAddress.number}
                    onChange={handleChange}
                    className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                    placeholder="Enter your number"
                  />
                  {errors.number && (
                    <p className="text-red-500 text-xs">{errors.number}</p>
                  )}
                </div>

                {/* Address Input */}
                <div className="mb-4 col-span-2">
                  <label className="block text-gray-700 text-sm font-semibold mb-2">
                    Address
                  </label>
                  <input
                    type="text"
                    name="address"
                    required
                    value={newAddress.address}
                    onChange={handleChange}
                    className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                    placeholder="Enter your address"
                  />
                  {errors.address && (
                    <p className="text-red-500 text-xs">{errors.address}</p>
                  )}
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-semibold mb-2">
                    Area
                  </label>
                  <input
                    type="text"
                    name="area"
                    value={newAddress.area}
                    onChange={handleChange}
                    className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                    placeholder="Enter your area"
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-semibold mb-2">
                    Landmark
                  </label>
                  <input
                    type="text"
                    name="landmark"
                    value={newAddress.landmark}
                    onChange={handleChange}
                    className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                    placeholder="Enter your landmark"
                  />
                </div>

                {/* Postal Code Input */}
                <div className="mb-4 col-span-2">
                  <label className="block text-gray-700 text-sm font-semibold mb-2">
                    Postal Code
                  </label>
                  <input
                    type="number"
                    name="postalCode"
                    required
                    value={newAddress.postalCode}
                    onChange={handleChange}
                    onBlur={fetchStateCity}
                    className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                    placeholder="Enter your postal code"
                  />
                  {errors.postalCode && (
                    <p className="text-red-500 text-xs">{errors.postalCode}</p>
                  )}
                </div>

                {/* City Input */}
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-semibold mb-2">
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={newAddress.city}
                    onChange={handleChange}
                    className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                    placeholder="Enter your city"
                  />
                  {errors.city && (
                    <p className="text-red-500 text-xs">{errors.city}</p>
                  )}
                </div>

                {/* State Input */}
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-semibold mb-2">
                    State
                  </label>
                  <input
                    type="text"
                    name="state"
                    value={newAddress.state}
                    onChange={handleChange}
                    className="border-gray-300 rounded-md px-3 py-2 focus:border-[#000080] focus:ring-[#000080] block w-full"
                    placeholder="Enter your state"
                  />
                  {errors.state && (
                    <p className="text-red-500 text-xs">{errors.state}</p>
                  )}
                </div>
              </form>

              <div className="mt-6 flex justify-end space-x-4">
                <button
                  onClick={
                    editingAddressId ? handleUpdateAddress : handleAddAddress
                  }
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                >
                  {editingAddressId ? "Update Address" : "Add Address"}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AddressSelection;
